import { useEffect, useState } from "react";
import { Input } from "../../components/input";
import { Button, ButtonColor, ButtonNavigate, ButtonSize } from "../../components/button";
import { ErrorMessage } from "../../components/error";
import { loginPageLink } from "../../util/relative-links";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../controllers/ajax/AuthController";

import { validateCode } from "../../controllers/ajax/InvitationCodeController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PageRoute } from "../../util/constants";
import { InvitationCodeType } from "../../../client-server-shared/enums";

interface Props {
    resetToken: string;
}

export const ResetPasswordForm = ({ resetToken }: Props) => {
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [reenteredPassword, setReenteredPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    enum ResetPasswordStage {
        DEFAULT = 0, // Default stage to avoid showing any UI until reset token has been verified.
        SET_NEW_PASSWORD = 1, // Show UI allowing user to set a new password.
        SUCCESS = 2, // Show UI indicating the password reset succeeded.
        RESET_LINK_NOT_VALID = 3, // Show error page indicating the reset link is not valid.
        PASSWORD_RESET_FAILED = 4, // Show error page indicating the password reset failed.
    }
    // Set stage to DEFAULT to avoid showing any UI elements until the resetToken has been checked
    // to either be valid or invalid.
    const [resetStage, setResetStage] = useState(ResetPasswordStage.DEFAULT);
    useEffect(() => {
        validateCode(resetToken, InvitationCodeType.RESET_PASSWORD)
            .then((result) => {
                if (result) {
                    setResetStage(ResetPasswordStage.SET_NEW_PASSWORD);
                } else {
                    setResetStage(ResetPasswordStage.RESET_LINK_NOT_VALID);
                }
            })
            .catch((e) => {
                console.error(e);
                setResetStage(ResetPasswordStage.RESET_LINK_NOT_VALID);
            });
    }, []);


    const handleResetPassword = () => {
        if (password != reenteredPassword) {
            setErrorMessage("Passwords don't match.");
        } else if (password.length < 6) {
            // TODO: Decide on more strict password rules, and ensure consistent validation in 
            // initial account creation and password reset flows.
            setErrorMessage("Password must be at least 6 characters.");
        } else {
            resetPassword(resetToken, password).then((result) => {
                if (result) {
                    setResetStage(ResetPasswordStage.SUCCESS);
                } else {
                    setResetStage(ResetPasswordStage.PASSWORD_RESET_FAILED);
                }
            }).catch((e) => {
                setResetStage(ResetPasswordStage.PASSWORD_RESET_FAILED);
            })
        }
    }

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
            }}
            className="flex flex-col w-[300px]"
        >
            {resetStage == ResetPasswordStage.SET_NEW_PASSWORD &&
                <div>
                    <h1 className="font-bold text-2xl text-teal-600 mb-8">
                        Enter New Password
                    </h1>
                    <div className="flex flex-col space-y-1 mb-10">
                        <label htmlFor="password" className="text-sm font-medium">
                            New Password
                        </label>
                        <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErrorMessage("");
                            }}
                            placeholder="At least 6 characters"
                        />
                        <label htmlFor="password" className="text-sm font-medium pt-4">
                            Re-enter Password
                        </label>
                        <Input
                            id="password2"
                            type="password"
                            value={reenteredPassword}
                            onChange={(e) => {
                                setReenteredPassword(e.target.value);
                                setErrorMessage("");
                            }}
                            placeholder="At least 6 characters"
                        />
                    </div>
                    <Button onClick={() => {
                        handleResetPassword();
                    }} size={ButtonSize.Full} type="button" disabled={!password || !reenteredPassword}>
                        Reset Password
                    </Button>
                </div>
            }
            {resetStage == ResetPasswordStage.SUCCESS &&
                <div>
                    <h1 className="font-bold text-2xl text-teal-600 mb-2">
                        <FontAwesomeIcon
                            icon={icon({ name: "circle-check" })}
                            className="text-green-400"
                        />
                        &nbsp;&nbsp;You are all set!
                    </h1>
                    <p className="mb-8">Your password has been reset. Please proceed to login.</p>
                    <Button type="button" onClick={() => {
                        navigate(loginPageLink());
                    }} size={ButtonSize.Full}>
                        Return to Login
                    </Button>
                </div>
            }
            {resetStage == ResetPasswordStage.RESET_LINK_NOT_VALID &&
                <div>
                    <h1 className="flex gap-2 items-center font-bold text-2xl text-teal-600 mb-2">
                        <FontAwesomeIcon
                            icon={icon({ name: "face-sad-tear", style: "regular" })}
                            className="text-teal-500"
                        />
                        Invalid link
                    </h1>
                    <p className="mb-8">This link is invalid, expired or has already been used.
                        Please try sending a new reset link or reach out to the Flourish team for help.</p>
                    <div className="flex flex-col gap-5">
                        <ButtonNavigate
                            to={PageRoute.RESET_PASSWORD}
                            color={ButtonColor.TealFilled}
                            size={ButtonSize.Full}>
                            Reset Password
                        </ButtonNavigate>
                        <ButtonNavigate
                            to={PageRoute.HOME}
                            color={ButtonColor.TealUnfilled}
                            size={ButtonSize.Full}>
                            Go to home screen
                        </ButtonNavigate>
                    </div>
                </div>
            }
            {resetStage == ResetPasswordStage.PASSWORD_RESET_FAILED &&
                <div>
                    <h1 className="flex gap-2 items-center font-bold text-2xl text-teal-600 mb-2">
                        <FontAwesomeIcon
                            icon={icon({ name: "face-sad-tear", style: "regular" })}
                            className="text-teal-500"
                        />
                        Password reset failed
                    </h1>
                    <p className="mb-8">
                        An error occurred while resetting your password. Please try again and contact the Flourish team if the problem persists.</p>
                    <div className="flex flex-col gap-5">
                        <ButtonNavigate
                            to={PageRoute.HOME}
                            color={ButtonColor.TealUnfilled}
                            size={ButtonSize.Full}>
                            Go to home screen
                        </ButtonNavigate>
                    </div>
                </div>
            }
            <div className="mt-2">
                <ErrorMessage message={errorMessage} />
            </div>
        </form>
    );
}