import classNames from "classnames";

export interface BackgroundImageConfig {
  data: string;
  position: "bottom-right" | "bottom-left" | "bottom-center" | "top-center" | string; // string only for custom positioning
}

export const getBackgroundImageStyles = (
  config: BackgroundImageConfig
): { classNames: string; styles: Record<string, string> } => {
  const { position, data } = config;
  // TODO: better way to get the custom position
  const customPosition =
    position !== "bottom-right" &&
    position !== "bottom-left" &&
    position !== "bottom-center" &&
    position !== "top-center" &&
    position;

  return {
    classNames: classNames("bg-no-repeat bg-cover", customPosition, {
      "bg-right-bottom": position === "bottom-right",
      "bg-left-bottom": position === "bottom-left",
      "bg-bottom": position === "bottom-center",
      "bg-top": position === "top-center",
    }),
    styles: { backgroundImage: `url(${data})` },
  };
};

export const CardColor = {
  Default: {
    background: "bg-[#006972]",
    text: "text-white",
  },
  Red: {
    background: "bg-red-0",
    text: "text-red-800",
  },
  Orange: {
    background: "bg-orange-0",
    text: "text-orange-800",
  },
  Yellow: {
    background: "bg-yellow-0",
    text: "text-yellow-800",
  },
  Mint: {
    background: "bg-mint-0",
    text: "text-mint-800",
  },
  Cyan: {
    background: "bg-cyan-0",
    text: "text-cyan-900",
  },
  Mist: {
    background: "bg-mist-0",
    text: "text-mist-800",
  },
  Green: {
    background: "bg-green-0",
    text: "text-green-800",
  },
  Blue: {
    background: "bg-blue-0",
    text: "text-blue-800",
  },
  Purple: {
    background: "bg-purple-0",
    text: "text-purple-800",
  },
  Gray: {
    background: "bg-gray-50",
    text: "text-teal-800",
  },
  Teal: {
    background: "bg-teal-0",
    text: "text-teal-800",
  },
  White: {
    background: "bg-white",
    text: "text-teal-800",
  }
} as const;
export type CardColor = (typeof CardColor)[keyof typeof CardColor];
